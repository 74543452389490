import React from 'react';
import { PageHeader } from '@/components/page-header';
import { GlobalSettingProvider } from '@/contexts/GlobalSettingContext';
import { Helmet } from 'react-helmet';
import { configs } from '@/constants/configs';
import { LoaderProvider, Puff } from '@agney/react-loading';
import { LazyLoadBackgroundImage } from '@/components/lazy-load-background-image';
import { graphql, StaticQueryDocument, useStaticQuery } from 'gatsby';
import { NewsletterSignupPageFooter } from '@/components/newsletter-signup-page-footer';
import './newsletter-signup-layout.scss';

export interface NewsletterSignupLayoutProps {
	children: React.ReactNode;
	className?: string;
	pageTitle?: string;
	pageDescription?: string;
}

const query: StaticQueryDocument = graphql`
	query {
		bgImage: file(relativePath: { eq: "images/contact/background.png" }) {
			childImageSharp {
				gatsbyImageData(
					quality: 90
          placeholder: BLURRED
				)
			}
		}
	}
`;

const NewsletterSignupLayout: React.FC<NewsletterSignupLayoutProps> = ({
	children,
	pageTitle = configs.defaultPageTitle,
	pageDescription = configs.defaultPageDescription,
}: NewsletterSignupLayoutProps) => {
	const { bgImage } = useStaticQuery(query);
	return (
		<LazyLoadBackgroundImage Tag="div" imageGraphql={bgImage} className="main-bg newsletter-signup-layout">
			<LoaderProvider indicator={<Puff width="50" />}>
				<GlobalSettingProvider>
					<Helmet>
						<title>{pageTitle}</title>
						<meta name="description" content={pageDescription} />
					</Helmet>
					<PageHeader />
					{children}
          <NewsletterSignupPageFooter />
				</GlobalSettingProvider>
			</LoaderProvider>
		</LazyLoadBackgroundImage>
	);
};

export { NewsletterSignupLayout };
