import React from 'react';
import { configs } from '@/constants/configs';
import '../styles/global.scss';
import { NAV_SOCIALMEDIALIST } from '@/constants/social-media';
import { SocialMediaButton } from '@/components/social-media-button';
import { NewsletterSignupLayout } from '@/layouts/newsletter-signup-layout';

const pageName: string = 'Newsletter sign up';
const scriptElementId: string = 'aweber-wjs-r6jgxpotn';
const scriptUrl: string = '//forms.aweber.com/form/61/262200961.js';

export default function NewsletterSignUp() {
	React.useEffect(() => {
		if (document.getElementById(scriptElementId)) {
			return;
		}
		const scriptElement: HTMLScriptElement =
			document.createElement('script');
		scriptElement.id = scriptElementId;
		scriptElement.src = scriptUrl;
		document.head.append(scriptElement);
	}, []);

	return (
		<NewsletterSignupLayout
			pageTitle={`${configs.defaultPageTitle} - ${pageName}`}
		>
			<div className="AW-Form-262200961 newsletter-signup-aweber-form"></div>
			<nav
				className="social-media-links-container"
				aria-labelledby="social media navigation"
			>
				{Object.keys(NAV_SOCIALMEDIALIST).map((item: string) => {
					return (
						item !== 'email' && (
							<SocialMediaButton
								socialMedia={item as SocialMediaName}
								key={item}
								url={
									NAV_SOCIALMEDIALIST[
										item as SocialMediaName
									] || ''
								}
							/>
						)
					);
				})}
			</nav>
		</NewsletterSignupLayout>
	);
}
